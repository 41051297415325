import React, { useState, useEffect } from 'react';
import { AdminSideBar, AdminTopBar } from '../components';
import AddClientModal from './AddClientModal'; // Adjust path as necessary
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Debounce function to limit the rate of calling the API
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const Client = () => {
    const [clients, setClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage] = useState(20);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [totalClient, setTotalClient] = useState(0);

    const API_URL = process.env.REACT_APP_BASE_API_URL;

    // Use debounce for search query
    const debouncedSearchQuery = useDebounce(searchQuery, 500);

    const fetchClients = async (page = 1, query = "") => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/api/manager/ojiiz/all-client?page=${page}&limit=${clientsPerPage}&search=${query}`, {
                credentials: 'include', // Include cookies in the request

            });
            const data = await response.json();
            setClients(data.users || []);
            setTotalPages(data.totalPages || 1);
            setTotalClient(data.totalUsers || 1);

        } catch (error) {
            toast.error('Failed to fetch clients');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClients(currentPage, debouncedSearchQuery); // Use debounced search query
    }, [currentPage, debouncedSearchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page on new search
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const exportToCSV = async () => {
        try {
            const response = await fetch(`${API_URL}/api/manager/ojiiz/client`, {
                credentials: 'include', // Include cookies in the request
            });

            if (!response.ok) {
                throw new Error('Failed to fetch all clients for CSV export');
            }

            const clients = await response.json();

            // Format CSV content with userName as the first column
            const csvHeaders = ["UserName,PlanType,Email,CompanyName,Date"];
            const csvRows = clients.map(client => {
                return `${client.userName},${client.planType},${client.email},${client.companyName || 'Not Available'},${client.totalCredit},${client.usedCredit},${client.phoneNumber || 'Not Available'},${client.createdAt.split('T')[0]}`;
            });

            const csvContent = "data:text/csv;charset=utf-8," + [csvHeaders, ...csvRows].join("\n");
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "clients_data.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            toast.error('Failed to export data to CSV');
        }
    };

    const updateClientStatus = async (clientId, isActive) => {
        try {
            const response = await fetch(`${API_URL}/api/manager/ojiiz/update-client-status/${clientId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request
                body: JSON.stringify({ isActive }),
            });

            if (!response.ok) {
                throw new Error('Failed to update client status');
            }

            setClients((prevClients) =>
                prevClients.map((client) =>
                    client._id === clientId ? { ...client, isActive } : client
                )
            );

            toast.success(`Client status updated successfully`);
        } catch (error) {
            toast.error('Failed to update client status');
        }
    };

    const handleAddClient = async (formData) => {
        try {
            const response = await fetch(`${API_URL}/api/manager/ojiiz/add-client`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to add client');
            }

            const newClient = await response.json();
            setClients([...clients, newClient]);
            toast.success('Client added successfully');
        } catch (error) {
            toast.error('Failed to add client');
            throw error;
        }
    };

    const handleEditClient = async (formData) => {
        try {
            const response = await fetch(`${API_URL}/api/manager/ojiiz/update-client/${formData._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to update client');
            }

            const updatedClient = await response.json();
            setClients((prevClients) =>
                prevClients.map((client) =>
                    client._id === updatedClient._id ? updatedClient : client
                )
            );
            toast.success('Client updated successfully');
        } catch (error) {
            toast.error('Failed to update client');
            throw error;
        }
    };

    const deleteClient = async (clientId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this client?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${API_URL}/api/manager/ojiiz/delete-client/${clientId}`, {
                method: 'DELETE',
                credentials: 'include', // Include cookies in the request
            });

            if (!response.ok) {
                throw new Error('Failed to delete client');
            }

            // Optimistically update the state to remove the deleted client from the list
            setClients((prevClients) => prevClients.filter((client) => client._id !== clientId));

            toast.success('Client deleted successfully');
        } catch (error) {
            toast.error('Failed to delete client');
        }
    };

    const openAddClientModal = () => {
        setIsEditMode(false);
        setCurrentClient(null);
        setShowModal(true);
    };

    const openEditClientModal = (client) => {
        setIsEditMode(true);
        setCurrentClient(client);
        setShowModal(true);
    };

    return (
        <div className='admin-page'>
            <AdminSideBar />
            <div className="admin-detail">
                <AdminTopBar />
                <ToastContainer />
                {isLoading && <div className="loader"></div>}
                <div className="content">
                    <div className="admin-header">
                        <span>
                            <h3>Client</h3>
                            <span>Total Clients: <b>{totalClient && totalClient}</b></span>
                        </span>
                        <button className='primary-button' onClick={openAddClientModal}>
                            +Add Client
                        </button>
                        <button className='secondary-button' onClick={exportToCSV}>
                            Export to CSV
                        </button>
                        <AddClientModal
                            show={showModal}
                            handleClose={() => setShowModal(false)}
                            handleAddClient={handleAddClient}
                            handleEditClient={handleEditClient}
                            isEditMode={isEditMode}
                            clientData={currentClient}
                        />
                    </div>

                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search clients..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>

                    <div className="table-wrapper">
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Sr#</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Company Name</th>
                                    <th>Plan Type</th>
                                    <th>Total Credit</th>
                                    <th>Used Credit</th>
                                    <th>Phone Number</th>
                                    <th>Date</th>
                                    <th>IsGoogle</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((client, index) => (
                                    <tr
                                        className={
                                            `${!client.isActive ? 'active-row' : ''} 
                                            ${client?.planType?.toLowerCase() === 'advanced' ? 'green-row' : ''} 
                                            ${client?.planType?.toLowerCase() === 'ultimate' ? 'blue-row' : ''}`
                                        }
                                        key={client._id}>
                                        <td>{index + 1 + (currentPage - 1) * clientsPerPage}</td>
                                        <td>{client.userName}</td>
                                        <td>{client.email}</td>
                                        <td>{client.companyName || "Not Available"}</td>
                                        <td>{client.planType}</td>
                                        <td>{client.totalCredit}</td>
                                        <td>{client.usedCredit}</td>
                                        <td>{client.phoneNumber ? client.phoneNumber : 'Not Available'}</td>
                                        <td>{client.createdAt.split('T')[0]}</td>
                                        <td>{client.isGoogleUser ? 'Yes' : 'No'}</td>
                                        <td>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={client.isActive}
                                                    onChange={() => updateClientStatus(client._id, !client.isActive)}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <MdModeEditOutline
                                                size={24}
                                                color='var(--primary-color)'
                                                className='icon'
                                                onClick={() => openEditClientModal(client)}
                                            />
                                        </td>
                                        <td>
                                            <MdDelete
                                                size={24}
                                                color='#8E0E07'
                                                className='icon'
                                                onClick={() => deleteClient(client._id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    <div className="pagination">
                        <span>Page {currentPage} of {totalPages}</span>

                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Client;
