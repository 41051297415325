import React, { useState, useEffect } from 'react';
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Footer, NavBar, SideBar } from '../../components';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../context/AdminLogout';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordChange = () => {
    const [loading, setLoading] = useState(false);
    const { ojiiz_user } = useAuthContext();
    const { logout } = useLogout();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [passwordUpdate, setPasswordUpdate] = useState('');
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');

    // State for showing/hiding passwords
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
    });

    const API_URL = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        if (!ojiiz_user.isGoogleUser) {
            const fetchUserData = async () => {
                try {
                    setIsLoading(true);
                    const response = await fetch(`${API_URL}/api/user/ojiiz/password-date/${ojiiz_user.userName}`, {
                        credentials: 'include',
                    });
                    const data = await response.json();
                    setPasswordUpdate(data.passwordUpdate);
                } catch (error) {
                    toast.error('An error occurred while fetching user data. Please try again later.');
                    setError(error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchUserData();
        }
    }, [ojiiz_user.userName, ojiiz_user.isGoogleUser, API_URL]);

    const isPasswordStrong = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const isLengthValid = password.length >= 8;

        return hasUpperCase && hasLowerCase && hasNumbers && hasSpecialCharacters && isLengthValid;
    };

    useEffect(() => {
        if (passwordData.newPassword) {
            if (!isPasswordStrong(passwordData.newPassword)) {
                setPasswordError('Password is not strong enough. It must contain at least 8 characters, including uppercase and lowercase letters, numbers, and special characters.');
            } else {
                setPasswordError('');
            }

            if (passwordData.newPassword === passwordData.currentPassword && !ojiiz_user.isGoogleUser) {
                setCurrentPasswordError('New password should not be the same as the old password.');
            } else {
                setCurrentPasswordError('');
            }
        }

        if (passwordData.confirmNewPassword) {
            if (passwordData.newPassword !== passwordData.confirmNewPassword) {
                setConfirmPasswordError('New password and confirm password do not match.');
            } else {
                setConfirmPasswordError('');
            }
        }
    }, [passwordData.newPassword, passwordData.confirmNewPassword, passwordData.currentPassword, ojiiz_user.isGoogleUser]);

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (passwordError || confirmPasswordError || currentPasswordError) {
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/user/ojiiz/${ojiiz_user.isGoogleUser ? 'set-password' : 'change-password'}/${ojiiz_user.userName}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(passwordData),
            });

            const data = await response.json();
            setLoading(false);

            if (response.ok) {
                toast.success(`${ojiiz_user.isGoogleUser ? 'Password set' : 'Password changed'} successfully.`);
                setTimeout(() => {
                    logout();
                }, 2000);
            } else if (response.status === 400) {
                toast.error('Invalid current password.');
            } else {
                toast.error(data.error || 'Failed to update password.');
            }
        } catch (error) {
            toast.error('An error occurred while updating the password. Please try again later.');
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({
            ...passwordData,
            [name]: value,
        });
    };

    // Toggle password visibility
    const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    return (
        <div>
            <div className="jobDetail-navbar">
                <NavBar />
                <h1>Password</h1>
            </div>
            {isLoading && <div className="loader"></div>}
            <div className="profile-content">
                <SideBar />
                <div className="profile-body">
                    <div className="profile-header">
                        <h2>{ojiiz_user.isGoogleUser ? 'Set Password' : 'Change Password'}</h2>
                        {!ojiiz_user.isGoogleUser && passwordUpdate && (
                            <p>Last updated on {new Date(passwordUpdate).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}</p>
                        )}
                    </div>
                    {error && <div className="warning">{error.message}</div>}
                    <form onSubmit={handlePasswordChange}>
                        <div className="row">
                            <div className="col">
                                {!ojiiz_user.isGoogleUser && (
                                    <label htmlFor="currentPassword">
                                        Old Password
                                        <div className="password-container">
                                            <input
                                                type={showPassword.currentPassword ? "text" : "password"}
                                                name="currentPassword"
                                                value={passwordData.currentPassword}
                                                onChange={handleChange}
                                                placeholder="Enter your old password"
                                                style={{ width: '100%' }}
                                                className={currentPasswordError ? 'error' : ''}
                                                required
                                            />
                                            <button
                                                type="button"
                                                onClick={() => togglePasswordVisibility('currentPassword')}
                                                className="show-hide-btn"
                                            >
                                                {showPassword.currentPassword ? <LuEyeOff size={24} /> : <LuEye size={24} />}
                                            </button>
                                        </div>
                                        {currentPasswordError && <p className="error-message">{currentPasswordError}</p>}
                                    </label>
                                )}
                                <label htmlFor="newPassword">
                                    New Password
                                    <div className="password-container">
                                        <input
                                            type={showPassword.newPassword ? "text" : "password"}
                                            name="newPassword"
                                            value={passwordData.newPassword}
                                            onChange={handleChange}
                                            placeholder="Enter your new password"
                                            style={{ width: '100%' }}
                                            className={passwordError ? 'error' : ''}
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            className="show-hide-btn"
                                        >
                                            {showPassword.newPassword ? <LuEyeOff size={24} /> : <LuEye size={24} />}
                                        </button>
                                    </div>
                                    {passwordError && <p className="error-message">{passwordError}</p>}
                                </label>
                                <label htmlFor="confirmNewPassword">
                                    Confirm New Password
                                    <div className="password-container">
                                        <input
                                            type={showPassword.confirmNewPassword ? "text" : "password"}
                                            name="confirmNewPassword"
                                            value={passwordData.confirmNewPassword}
                                            onChange={handleChange}
                                            placeholder="Confirm your new password"
                                            style={{ width: '100%' }}
                                            className={confirmPasswordError ? 'error' : ''}
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('confirmNewPassword')}
                                            className="show-hide-btn"
                                        >
                                            {showPassword.confirmNewPassword ? <LuEyeOff size={24} /> : <LuEye size={24} />}
                                        </button>
                                    </div>
                                    {confirmPasswordError && <p className="error-message">{confirmPasswordError}</p>}
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="profile-btn" disabled={loading}>Submit</button>
                    </form>
                </div>
            </div>
            <Footer />
            <ToastContainer />
        </div>
    );
};

export default PasswordChange;
