import { useContext, useState } from 'react';
import { AdminAuthContext } from './AdminAuthContext'; // Admin auth context
import { useNavigate } from "react-router-dom";

export const useAdminLogin = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = useContext(AdminAuthContext); // Admin AuthContext
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const navigate = useNavigate();

    const login = async (email, password) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${API_URL}/api/manager/auth/admin-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request
                body: JSON.stringify({ email, password }),
            });

            const json = await response.json();

            if (!response.ok) {
                setError(json.error);
                setIsLoading(false);
                return;
            }

            // Save the admin info to localStorage
            localStorage.setItem('ojiiz_admin', JSON.stringify(json));

            // Update the AdminAuthContext
            dispatch({ type: 'LOGIN', payload: json });

            setIsLoading(false);
            navigate('/admin'); // Navigate to the admin dashboard
        } catch (error) {
            setError('Something went wrong. Please try again.');
            setIsLoading(false);
        }
    };

    return { login, isLoading, error };
};
