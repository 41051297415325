import React, { useEffect, useState, useMemo } from 'react';
import { AdminSideBar, AdminTopBar } from '../components';
import { FaUser } from "react-icons/fa6";
import { HiMiniUserGroup } from "react-icons/hi2";
import { LiaBandcamp } from "react-icons/lia";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Admin.css';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment'; // This is required for time axis functionality
import { useLogout } from './account/AdminLogout';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, TimeScale); // Register TimeScale here


const AdminHome = () => {
    const [totalJobs, setTotalJobs] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const [clientsData, setClientsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [timeRange, setTimeRange] = useState('day');

    const { logout } = useLogout();


    const API_URL = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const totalJobsResponse = await fetch(`${API_URL}/api/manager/ojiiz/all-job`, {
                    credentials: 'include', // Include cookies in the request
                });

                if (totalJobsResponse.status === 401) {
                    logout();
                    return; // Stop further execution
                }

                if (totalJobsResponse.ok) {
                    const jobsData = await totalJobsResponse.json();
                    setTotalJobs(jobsData.totalJobs);
                } else {
                    toast.error('Failed to fetch jobs');
                }

                const clientsResponse = await fetch(`${API_URL}/api/manager/ojiiz/client`, {
                    credentials: 'include', // Include cookies in the request
                });

                if (clientsResponse.status === 401) {
                    logout();
                    return;
                }

                if (clientsResponse.ok) {
                    const clientsData = await clientsResponse.json();
                    setTotalClients(clientsData.length);
                    setClientsData(clientsData);
                } else {
                    toast.error('Failed to fetch clients');
                }

                const usersResponse = await fetch(`${API_URL}/api/manager/ojiiz/admin-user`, {
                    credentials: 'include', // Include cookies in the request
                });

                if (usersResponse.status === 401) {
                    logout();
                    return;
                }

                if (usersResponse.ok) {
                    const usersData = await usersResponse.json();
                    setTotalUsers(usersData.length);
                } else {
                    toast.error('Failed to fetch users');
                }

            } catch (error) {
                toast.error('Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [API_URL]);


    const handleTimeRangeChange = (event) => {
        setTimeRange(event.target.value);
    };

    const chartData = useMemo(() => {
        const format = timeRange === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD';
        const dateCountMap = clientsData.reduce((acc, client) => {
            const date = moment(client.createdAt).startOf(timeRange).format(format);
            if (!acc[date]) {
                acc[date] = 0;
            }
            acc[date]++;
            return acc;
        }, {});

        return {
            labels: Object.keys(dateCountMap).sort((a, b) => new Date(a) - new Date(b)),
            datasets: [
                {
                    label: 'Total Clients',
                    data: Object.values(dateCountMap),
                    borderColor: '#8884d8',
                    fill: false,
                    tension: 0.1,
                },
            ],
        };
    }, [clientsData, timeRange]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `Total Clients: ${tooltipItem.raw}`,
                },
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: timeRange === 'month' ? 'month' : 'day',
                    displayFormats: {
                        day: 'D MMM, YYYY',
                        month: 'MMM YYYY',
                    },
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className='admin-page'>
            <AdminSideBar />
            <ToastContainer />
            <div className="admin-detail">
                <AdminTopBar />
                {isLoading && <div className="loader"></div>}
                <div className="admin-content">
                    <div className="admin-box-row">
                        <div className="admin-box">
                            <span>
                                <h3>Total Clients</h3>
                                <p>{totalClients}</p>
                            </span>
                            <HiMiniUserGroup size={50} color='white' />
                        </div>
                        <div className="admin-box" style={{ backgroundColor: '#D4E6F1' }}>
                            <span>
                                <h3>Total Jobs</h3>
                                <p>{totalJobs}</p>
                            </span>
                            <LiaBandcamp size={50} color='white' />
                        </div>
                        <div className="admin-box" style={{ backgroundColor: 'var(--primary-color)' }}>
                            <span>
                                <h3>Total Admin's</h3>
                                <p>{totalUsers}</p>
                            </span>
                            <FaUser size={50} color='white' />
                        </div>
                    </div>
                    <div className="admin-line-chart">
                        <div className="chart-controls">
                            <label>
                                <input
                                    type="radio"
                                    value="day"
                                    checked={timeRange === 'day'}
                                    onChange={handleTimeRangeChange}
                                />
                                <span>Day</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="week"
                                    checked={timeRange === 'week'}
                                    onChange={handleTimeRangeChange}
                                />
                                <span>Week</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="month"
                                    checked={timeRange === 'month'}
                                    onChange={handleTimeRangeChange}
                                />
                                <span>Month</span>
                            </label>
                        </div>
                        <div className="admin-chart">
                            <Line data={chartData} options={chartOptions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;
