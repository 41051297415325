import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const SuccessPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const navigate = useNavigate();

    // Function to parse query parameters
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const session_id = query.get('session_id'); // Get session_id from the query

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                const response = await fetch(`${API_URL}/api/user/ojiiz/retrieve-session?session_id=${session_id}`, {
                    credentials: 'include',
                });

                if (response.status === '200') {
                    const session = await response.json();
                    setStatus(session.status);

                    if (session.status === 'complete') {
                        // Update localStorage with the new planType
                        const ojiizUser = JSON.parse(localStorage.getItem('ojiiz_user'));
                        ojiizUser.planType = session.metadata.planType;
                        localStorage.setItem('ojiiz_user', JSON.stringify(ojiizUser));

                        toast.success('Your oz credits added successfully');
                    } else if (session.status === 'open') {
                        toast.info('Transaction is still open.');
                    } else if (session.status === 'expired') {
                        toast.error('Transaction has expired.');
                    }
                } else {
                    toast.info('Credits have already been added for this transaction.');
                }
            } catch (err) {
                setError('Failed to retrieve session data');
            } finally {
                setLoading(false);
            }
        };

        if (session_id) {
            fetchSessionData();
        } else {
            setError('Session ID is missing');
            setLoading(false);
        }
    }, [session_id, API_URL]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (status === 'open') {
        return navigate('/check-out');
    }

    return (
        <div style={styles.body}>
            <ToastContainer />
            <div className="card" style={styles.card}>
                <div style={styles.circle}>
                    <i className="checkmark">✓</i>
                </div>
                <h1 style={styles.title}>Success</h1>
                <p style={styles.paragraph}>Your transaction has been successfully completed.</p>
                <center>
                    <button style={styles.button} onClick={() => navigate('/choose-plan')}>
                        Proceed
                    </button>
                </center>
            </div>
        </div>
    );
};

const styles = {
    body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '40px 0',
        background: '#EBF0F5',
        height: '100vh',
    },
    title: {
        color: '#88B04B',
        fontFamily: 'Nunito Sans, Helvetica Neue, sans-serif',
        fontWeight: 900,
        fontSize: '40px',
        marginBottom: '10px',
    },
    paragraph: {
        color: '#404F5E',
        fontFamily: 'Nunito Sans, Helvetica Neue, sans-serif',
        fontSize: '20px',
        margin: 0,
    },
    circle: {
        borderRadius: '200px',
        height: '200px',
        width: '200px',
        background: '#F8FAF5',
        margin: '0 auto',
    },
    card: {
        background: 'white',
        padding: '60px',
        borderRadius: '4px',
        boxShadow: '0 2px 3px #C8D0D8',
        display: 'inline-block',
        margin: '0 auto',
    },
    button: {
        marginTop: '20px',
        padding: '15px 50px',
        borderRadius: '20px',
        background: '#706BEB',
        color: 'white',
        fontSize: '16px',
    },
};

export default SuccessPage;
