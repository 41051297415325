import React, { useState, useEffect, useRef } from 'react';
import { Footer, NameAnimation, NavBar, Tour } from '../../components';
import { CiCalendar } from "react-icons/ci";
import { GoStack } from "react-icons/go";
import { FaArrowDown, FaPhoneVolume, FaEyeSlash } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { ImLinkedin } from "react-icons/im";
import { IoMailSharp, IoCloseOutline } from "react-icons/io5";
import { useAuthContext } from '../../hooks/useAuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FeatureJobs from './FeatureJobs';
import { useUserCredit } from '../../context/UserCreditContext';
import noJob from '../../assets/no-job-detail.png';

// for tag icons
import Individual from '../../assets/tag-icon/individual.png';
import Multi from '../../assets/tag-icon/mix.png';
import Hourly from '../../assets/tag-icon/hourly.png';
import FixedCost from '../../assets/tag-icon/fixed-cost.png';
import Agencey from '../../assets/tag-icon/agencey.webp';

// Define colors and icons for specific tags
const tagStyles = {
    'Fixed Cost': { color: '#33AAB6', icon: FixedCost },
    'Hourly': { color: '#48bb78', icon: Hourly },
    'Agency Hiring': { color: '#4299e1', icon: Agencey },
    'Individual Hiring': { color: '#ed64a6', icon: Individual },
    'Multi-source Hiring': { color: '#DB9E4F', icon: Multi }
};

const JobDetail = () => {
    const { ojiiz_user } = useAuthContext();
    const { Credit, updateCredits } = useUserCredit();
    const navigate = useNavigate();
    const { id } = useParams();
    const hiddenSectionRef = useRef(null);
    const [jobs, setJobs] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [jobNotFound, setJobNotFound] = useState(false);
    // const [isSavingJob, setIsSavingJob] = useState(false);
    const [isDeductingCredits, setIsDeductingCredits] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [hiddenVisible, setHiddenVisible] = useState(false);
    const [phoneCredit, setPhoneCredit] = useState(false);
    // const [savedJob, setSavedJob] = useState(false);
    const [showTour, setShowTour] = useState(false);

    // localStorage.clear();

    useEffect(() => {
        // Check if tour has been shown before
        const hasSeenTour = localStorage.getItem('hasSeenDetailTour1');

        if (!hasSeenTour) {
            // Start the tour after 5 seconds if the user hasn't seen it before
            const timer = setTimeout(() => {
                setShowTour(true);
                localStorage.setItem('hasSeenDetailTour1', 'true'); // Mark tour as seen
            }, 2000); // 2000ms = 2 seconds

            return () => clearTimeout(timer); // Clear timeout if component unmounts
        }
    }, []);

    const [userData, setUserData] = useState({
        userName: '',
        credits: 0,
    });
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setHiddenVisible(false);
            setIsDeductingCredits(false);
            setPhoneCredit(false);
            try {
                const response = await fetch(`${API_URL}/api/user/ojiiz/job/${id}/${ojiiz_user.userName}`, {
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();

                    setJobs(data); // Set the job data
                    setUserData({
                        userName: data.userName,
                        credits: data.totalCredit - data.usedCredit
                    });

                    setHiddenVisible(data?.mainCredit)
                    setPhoneCredit(data?.phoneCredit)

                } else {
                    setJobNotFound(true);
                    toast.error('Job not found. It may have been removed by the admin.');
                }
            } catch (error) {
                if (error.name === 'TypeError') {
                    toast.error('Network error. Please check your connection.');
                } else {
                    toast.error('An unexpected error occurred. Please try again later.');
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, ojiiz_user.userName, API_URL]);

    const tagsArray = jobs?.tags ? jobs.tags.split(',').map(tag => tag.trim()) : [];

    // const saveJob = async () => {
    //     if (isSavingJob) return;
    //     setIsSavingJob(true);
    //     try {
    //         const response = await fetch(`${API_URL}/api/user/ojiiz/save-job`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',

    //             },
    // credentials: 'include',
    //             body: JSON.stringify({ userName: ojiiz_user.userName, jobId: id }),
    //         });
    //         if (response.ok) {
    //             toast.success('Job saved successfully!');
    //             setSavedJob(true);
    //         } else {
    //             const data = await response.json();
    //             toast.error(data.message);
    //         }
    //     } catch (error) {
    //         toast.error('An error occurred while saving job. Please try again later.');
    //     } finally {
    //         setIsSavingJob(false);
    //     }
    // };

    const scrollToHiddenSection = () => {
        if (window.innerWidth <= 1024) {
            setIsPopupVisible(true);
        } else if (hiddenSectionRef.current) {
            hiddenSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const formatDate = (jobDate) => {
        const date = new Date(jobDate);
        if (isNaN(date.getTime())) return "";
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }).format(date);
    };

    const renderHTML = (htmlContent) => ({ __html: htmlContent });

    const verifyEmail = async (id) => {
        try {
            const response = await fetch(`${API_URL}/api/user/ojiiz/verify-job-email?id=${id}&userName=${userData.userName}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();

                // Handle success case: Email verified and credits updated
                if (data.result === 'Email verified') {
                    return { isValid: true }; // Return an object indicating success
                }
            } else {
                const data = await response.json();
                if (data.error === 'Insufficient credits') {
                    toast.error('You do not have enough credits to proceed.');
                    return { isValid: false, reason: 'insufficient_credits' }; // Indicate insufficient credits
                } else if (data.error === 'Email is undeliverable') {
                    toast.error('The email for this job is undeliverable.');
                    return { isValid: false, reason: 'undeliverable_email' }; // Indicate undeliverable email
                } else {
                    toast.error(data.error || 'Failed to verify email');
                }
            }
        } catch (error) {
            toast.error('Failed to verify email. Please try again later.');
        }
        return { isValid: false }; // Return false if email verification fails
    };



    const deductCredits = async (credit, type) => {
        if (isDeductingCredits || userData.credits <= 0 || !jobs) {
            toast.info("You don't have enough oz.");
            return;
        }
        setIsDeductingCredits(true);

        try {
            // Verify email if type is 'main'
            const { isValid, reason } = type === 'main' ? await verifyEmail(id) : { isValid: true };


            if (!isValid) {
                if (reason === 'insufficient_credits') {
                    // Do not proceed further as user doesn't have enough credits
                    return;
                }

                toast.error('Email verification failed. The job will be deleted shortly, and credits will be refunded.');

                // Set a timeout before deleting the job
                const timeoutId = setTimeout(async () => {
                    try {
                        const response = await fetch(`${API_URL}/api/user/ojiiz/user-delete-job/${id}`, {
                            method: 'DELETE',
                            credentials: 'include',
                        });

                        if (response.ok) {
                            toast.info('The job has been deleted.');
                            navigate('/jobs');
                        } else {
                            const data = await response.json();
                            toast.error(data.message);
                        }
                    } catch (error) {
                        toast.error('Error deleting job:', error);
                    }
                }, 5000); // 5-second delay before deleting the job

                return () => clearTimeout(timeoutId);
            }

            // Deduct credits if email verification is successful or not required

            // Deduct credits and reveal hidden data in one request

            const response = await fetch(`${API_URL}/api/user/ojiiz/deduct-credits`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userName: ojiiz_user.userName, credit, job_id: id, type }),
            });

            if (response.ok) {
                const data = await response.json();
                updateCredits(Credit - credit); // Update user credits
                setUserData(prevUserData => ({
                    ...prevUserData,
                    credits: data.credits,  // Update userData credits
                }));
                setJobs(prevJobs => ({
                    ...prevJobs,
                    companyName: data.hiddenData.mainVisible ? data.hiddenData.jobDetails.companyName : null,

                    email: data.hiddenData.mainVisible ? data.hiddenData.jobDetails.email : null,

                    linkedin: data.hiddenData.mainVisible ? data.hiddenData.jobDetails.linkedin : null,

                    postedBy: data.hiddenData.mainVisible ? data.hiddenData.jobDetails.postedBy : null,


                    companyPhone: data.hiddenData.phoneVisible ? data.hiddenData.jobDetails.companyPhone : null,

                }));
                setPhoneCredit(data.hiddenData.phoneVisible);
                setHiddenVisible(data.hiddenData.mainVisible);
                toast.success(`${credit} oz deducted.`);
            } else {
                const data = await response.json();
                toast.error(data.message);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again later.');
        } finally {
            setIsDeductingCredits(false);
        }
    };


    return (
        <div>
            <div className="jobDetail-navbar">
                <NavBar home={true} />
            </div>
            <ToastContainer />
            {isLoading ? (
                <NameAnimation />
            ) : (
                jobNotFound ?
                    <div className='no-job-detail'>
                        <img src={noJob} alt="" width={400} />
                        <Link to={"/jobs"}>
                            <button className='primary-btn'>Explore Related Jobs</button>
                        </Link>
                    </div>
                    :
                    <div className="job-detail">
                        <h2>{jobs?.jobTitle}</h2>

                        <div className="tags-list">
                            {tagsArray.map((tag, index) => {
                                const { color, icon } = tagStyles[tag] || { color: '#cbd5e0', icon: null };
                                return (
                                    <div
                                        key={index}
                                        className="tag"
                                        style={{
                                            color: color, // Text color only
                                            display: 'flex',
                                            border: `1px solid ${color}` // Optional border for better visibility
                                        }}
                                    >
                                        {icon &&
                                            <img src={icon} alt={icon} style={{ marginRight: '0.2rem' }} width={24} height={24} />

                                        }
                                        <span>{tag}</span>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="job-detail-row">
                            <div className="job-overview">
                                {/* <h3>Job Overview</h3> */}
                                <div className="overview-row">
                                    <div className="job-overview-detail">
                                        <CiCalendar size={32} color='#706BEB' />
                                        <span>Job Posted:</span>
                                        <p>{formatDate(jobs?.jobDate)}</p>
                                    </div>
                                    <div className="vertical-line"></div>
                                    <div className="job-overview-detail">
                                        <GoStack size={32} color='#706BEB' />
                                        <span>Category</span>
                                        <p>{jobs?.jobCategory}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="more-detail">
                                <button className='detail-btn' onClick={scrollToHiddenSection}>
                                    Show more details <FaArrowDown />
                                </button>
                            </div>
                        </div>


                        <div className="job-desc" id='jobDetailSection'>
                            <div className="job-desc-text">
                                <h3>Job Description</h3>
                                <div className="job-detail-heading">{jobs?.jobHeading}</div>
                                <div className='job-main-div' dangerouslySetInnerHTML={renderHTML(jobs?.jobDetail)} />
                            </div>

                            {/* for desktop devcie */}
                            <div ref={hiddenSectionRef}
                                className="hidden"
                                id='accessButton'
                            >

                                {!hiddenVisible && (
                                    <>
                                        <FaEyeSlash size={107} className='eye-icon' />
                                        <button
                                            onClick={() => deductCredits(1, 'main')}
                                        >
                                            {isDeductingCredits ? (
                                                <div className="loader-btn"></div>
                                            ) : (
                                                <>
                                                    Click to access
                                                    <i>It costs 1 oz</i>
                                                </>
                                            )}
                                        </button>
                                    </>
                                )}
                                <ContactInfo
                                    jobs={jobs}
                                    hiddenVisible={hiddenVisible}
                                    phoneCredit={phoneCredit}
                                    deductCredits={deductCredits}
                                    isDeductingCredits={isDeductingCredits}
                                />
                            </div>


                            {/* for mobile device */}
                            {isPopupVisible && (
                                <div className="popup">
                                    <button className="popup-close" onClick={() => setIsPopupVisible(false)}><IoCloseOutline size={34} color='white' /></button>
                                    <div className="hidden">
                                        {!hiddenVisible && (
                                            <>
                                                <FaEyeSlash size={107} className='eye-icon' />
                                                <button onClick={() => deductCredits(1, 'main')}>
                                                    {isDeductingCredits ? (
                                                        <div className="loader-btn "></div>
                                                    ) : (
                                                        <>
                                                            Click to access
                                                            <i>It costs 1 oz</i>
                                                        </>
                                                    )}
                                                </button>
                                            </>
                                        )}

                                        {/* for mobile */}
                                        <ContactInfo
                                            jobs={jobs}
                                            hiddenVisible={hiddenVisible}
                                            phoneCredit={phoneCredit}
                                            deductCredits={deductCredits}
                                            isDeductingCredits={isDeductingCredits}
                                        />

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
            )}
            {showTour && <Tour />}

            <FeatureJobs userData={userData} jobId={id} category={jobs?.jobCategory} />
            <Footer />
        </div>
    );
};

const truncateText = (content, maxLength) => {
    return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
};

const ContactInfo = ({ jobs, hiddenVisible, phoneCredit, deductCredits, isDeductingCredits }) => (
    <div className={hiddenVisible ? "job-hidden" : "job-hidden job-hidden-blur"}>
        <p>Contact Info</p>
        <h2>{hiddenVisible ? jobs?.postedBy : 'Posted By'}</h2>
        <ul>
            <li><FaBuilding size={25} color='#E7F0FA' />{hiddenVisible ? jobs?.companyName : 'Corporate Systems Inc'}</li>
            <li><ImLinkedin size={25} color='#E7F0FA' />
                <a href={hiddenVisible ? jobs?.linkedin : '#'} target="_blank" rel="noopener noreferrer">
                    {hiddenVisible ? truncateText(jobs?.linkedin, 25) : 'LinkedIn'}
                </a>
            </li>
            <li>
                <IoMailSharp size={25} color='#E7F0FA' />
                <a href={`mailto:${hiddenVisible ? jobs?.email : '#'}`}>
                    {hiddenVisible ? truncateText(jobs?.email, 25) : 'demo@gmail.com'}
                </a>
            </li>
            {hiddenVisible && (
                <li>
                    <FaPhoneVolume size={25} color='#E7F0FA' />
                    {phoneCredit ? (
                        <a href={`tel:${jobs?.companyPhone}`}>{jobs?.companyPhone}</a>
                    ) : (
                        'Phone Number (requires 1 oz)'
                    )}
                    {isDeductingCredits ? (
                        <div className="loader-btn"></div>
                    ) : (
                        !phoneCredit && (
                            <FaEyeSlash className='icon' color='#E7F0FA' size={25} onClick={() => deductCredits(1, 'phone')} />
                        )
                    )}
                </li>
            )}
        </ul>
    </div>
);

export default JobDetail;
