import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserCredit } from '../../context/UserCreditContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { FeatureJobLoader } from '../../components';
import { CiCalendar } from 'react-icons/ci';
import { GoDotFill } from "react-icons/go";
import { formatDistanceToNow, parseISO, subHours } from 'date-fns';


const FeatureJobs = ({ jobId, category }) => {
    const { Credit } = useUserCredit();
    const [isLoading, setIsLoading] = useState(true);
    const [featureJob, setFeatureJob] = useState([]);
    const navigate = useNavigate();


    const formatJobDate = (job) => {
        const jobDate = parseISO(job.jobDate);
        const adjustedJobDate = subHours(jobDate, 5);
        const timeAgo = formatDistanceToNow(adjustedJobDate, { addSuffix: true, includeSeconds: true });
        return timeAgo;
    };

    const handleJobClick = () => {
        toast.info("You have no more oz.");
        setTimeout(() => {
            navigate("/choose-plan");
        }, 2000);
    };

    const API_URL = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        const fetchJobsByCategory = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${API_URL}/api/user/ojiiz/jobs-by-category?jobCategory=${category}`, {
                    credentials: 'include',
                });
                const latestJobsData = await response.json();
                setFeatureJob(latestJobsData.filter(job => job._id !== jobId).slice(0, 4));

            } catch (error) {
                toast.error('An error occurred while fetching jobs. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };
        if (category) {
            fetchJobsByCategory();
        }

    }, [API_URL, jobId, category]);

    return (
        <section>
            {isLoading ? (
                <FeatureJobLoader />
            ) : (
                <div className="more-job-section">
                    {featureJob?.length > 0 && (
                        <>
                            <h2>Claim Your Leads Before Competitors Do</h2>
                            {/* <p>The Opportunity is Here Make Sure These Leads Pay You, Don't Let Them Go To a Competitor</p> */}
                            <div className="more-job-row">
                                {featureJob?.map(job => (
                                    <div className="more-job" key={job._id}>
                                        <h3>{truncateText(job?.jobTitle, 40)}</h3>
                                        <span>
                                            <CiCalendar /> {formatJobDate(job)} <GoDotFill size={14} /> {job.jobCategory}
                                        </span>
                                        <p>{extractAndTruncateContent(job?.jobDetail, 100)}</p>
                                        {Credit <= 0 ? (
                                            <div onClick={() => handleJobClick()}>
                                                <button>See More</button>
                                            </div>
                                        ) : (
                                            <Link to={`/jobs-detail/${job?._id}`}>
                                                <button>See More</button>
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            )}
        </section>
    );
};

const truncateText = (content, maxLength) => {
    return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
};

// Function to extract and truncate content, skipping headings
const extractAndTruncateContent = (htmlString, maxLength) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    const childNodes = tempElement.childNodes;
    let content = '';

    for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i];
        if (node.nodeName.toLowerCase().startsWith('h')) {
            continue;
        }
        if (node.textContent.trim()) {
            content += node.textContent.trim() + ' ';
        }
        if (content.length >= maxLength) {
            break;
        }
    }
    return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
};

export default FeatureJobs;
