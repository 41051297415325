import { useContext, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const navigate = useNavigate();

    const login = async (email, password) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${API_URL}/api/user/auth/user-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include', // Include cookies in the request
            });

            const json = await response.json();

            if (!response.ok) {
                setIsLoading(false);
                toast.error(json.error);
                return;
            }
            // Save user information in localStorage
            localStorage.setItem('ojiiz_user', JSON.stringify(json));

            // Save user information in the AuthContext
            dispatch({ type: 'LOGIN', payload: json });

            toast.success('User logged in successfully!');
            setTimeout(() => {
                navigate('/'); // Navigate to the home page or user dashboard
            }, 2000);
        } catch (error) {
            setError(error.message);
            toast.error('Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return { login, isLoading, error };
};
