import { useAuthContext } from '../hooks/useAuthContext';

export const useLogout = () => {
    const { dispatch } = useAuthContext();

    const logout = async () => {
        try {
            // Call the backend logout endpoint to clear the user_token cookie
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/user/auth/logout`, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                // Remove user data from localStorage
                localStorage.removeItem('ojiiz_user');

                // Dispatch logout action to update AuthContext
                dispatch({ type: 'LOGOUT' });
            } else {
                console.error('Failed to log out on the backend');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return { logout };
};
