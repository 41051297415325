import { useAdminAuthContext } from '../../../hooks/useAdminAuthContext';

export const useLogout = () => {
    const { dispatch } = useAdminAuthContext();

    const logout = async () => {
        try {
            // Call the backend logout endpoint
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/manager/auth/admin-log-out`, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                // Remove admin info from local storage
                localStorage.removeItem('ojiiz_admin');

                // Dispatch logout action
                dispatch({ type: 'LOGOUT' });

            } else {
                console.error('Failed to log out');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return { logout };
};
