import React, { useState, useEffect, useCallback } from 'react';
import { Footer, Jobs, NavBar, SideBar } from '../components';
import { useAuthContext } from '../hooks/useAuthContext';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import saveJobImg from '../assets/save-job.png';

const SavedJobs = () => {
    const [savedJobs, setSavedJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');

    const { ojiiz_user } = useAuthContext();
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const fetchSavedJobs = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/api/user/ojiiz/user-saved-jobs/${ojiiz_user.userName}`, {
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch saved jobs');
            }

            const data = await response.json();
            setSavedJobs(data.savedJobs);
        } catch (error) {
            toast.error('Error fetching saved jobs: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    }, [API_URL, ojiiz_user.userName]);

    useEffect(() => {
        fetchSavedJobs();
    }, [fetchSavedJobs]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    // Filter the jobs based on the search term
    const filteredJobs = savedJobs.filter(job =>
        (job.jobTitle && job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (job.jobCategory && job.jobCategory.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;

    // Slice the filtered jobs for the current page
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

    const paginate = pageNumber => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);
    const maxPageButtons = 5;

    const getPageNumbers = () => {
        const pages = [];
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = startPage + maxPageButtons - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div>
            <div className="jobDetail-navbar">
                <NavBar home={true} />
                <h1>Saved Jobs</h1>
            </div>
            <ToastContainer />
            {isLoading && <div className="loader"></div>}
            <div className="saved-content">
                <SideBar />
                <div style={{ paddingBottom: '50px', width: '100%' }}>
                    <div className="save-job-header">
                        <div>
                            <input
                                style={{ width: '90%', margin: '1rem' }}
                                className='search-input'
                                type="text"
                                placeholder="Search by job title, Category..."
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)} // Handle input change
                            />
                        </div>
                    </div>
                    <div className="export-header-text">
                        <h2>Jobs Data</h2>
                        <p>Total saved jobs: <span>{filteredJobs?.length}</span></p>
                    </div>
                    <div className="saved-job">
                        {filteredJobs.length > 0 ? currentJobs.map(job => (
                            <React.Fragment key={job._id}>
                                <Link to={`/jobs-detail/${job._id}`}>
                                    <Jobs job={job} />
                                </Link>
                            </React.Fragment>
                        )) : <img src={saveJobImg} alt="" width={400} className='no-item' />}
                    </div>
                    {filteredJobs.length > 0 && (
                        <div className="pagination">
                            <span>Page {currentPage} of {totalPages}</span>
                            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Prev</button>
                            {getPageNumbers().map((number) => (
                                <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'active' : ''}>
                                    {number}
                                </button>
                            ))}
                            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SavedJobs;
