import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';
import { AuthContext } from '../../context/AuthContext';

const VerifyMail = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [isResending, setIsResending] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSent, setIsSent] = useState(true);
    const { dispatch } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const isGoogleUser = location.state?.isGoogleUser || false;
    const formData = location.state?.formData || null;
    const email = formData?.email || '';
    const maskedEmail = email.replace(/(.{3})(.*)(?=@)/, (gp1, gp2, gp3) => gp2 + "*".repeat(gp3.length));

    useEffect(() => {
        if (!formData && !isGoogleUser) navigate('/sign-up');
    }, [formData, isGoogleUser, navigate]);

    const handleVerification = async (e) => {
        e.preventDefault();
        if (verificationCode.length !== 6) {
            toast.info('Verification code must be 6 digits');
            return;
        }
        setIsProcessing(true);

        try {
            const response = await fetch(`${API_URL}/api/user/auth/verify-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, verificationCode }),
            });

            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(errorDetails.message || 'Invalid verification code');
            }

            toast.success('Verification successful');
            await handleSignUp();

        } catch (error) {
            toast.error(error.message);
            setIsProcessing(false);
        }
    };

    const handleSignUp = async () => {
        if (!isGoogleUser && formData) {
            const requiredFields = ['firstName', 'lastName', 'userName', 'email', 'password', 'confirmPassword'];
            const emptyFields = requiredFields.filter(field => !formData[field]);

            if (emptyFields.length > 0) {
                toast.error('Some required fields are empty. Please fill them in.');
                setIsProcessing(false);
                return;
            }

            if (!validator.isEmail(formData.email)) {
                toast.error('Invalid email format');
                setIsProcessing(false);
                return;
            }

            if (formData.password !== formData.confirmPassword) {
                toast.error('Passwords do not match.');
                setIsProcessing(false);
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/user/auth/user-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(formData),
                });

                if (!response.ok) {
                    const errorDetails = await response.json();
                    throw new Error(errorDetails.message || 'Sign-up failed');
                }

                const userData = await response.json();
                localStorage.setItem('ojiiz_user', JSON.stringify(userData));
                dispatch({ type: 'LOGIN', payload: userData });

                toast.success('Sign-up successful! Enjoy your 5 free oz');
                navigate('/');
            } catch (error) {
                toast.error(`Sign-up failed: ${error.message}`);
            } finally {
                setIsProcessing(false);
            }
        } else {
            toast.success('You are now signed in!');
            navigate('/');
        }
    };

    const handleResend = async () => {
        setIsResending(true);
        try {
            const response = await fetch(`${API_URL}/api/user/auth/send-verification-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(errorDetails.message || 'Error resending verification code');
            }

            toast.info('Verification code resent to your email');
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsResending(false);
            setIsSent(true);
        }
    };

    const handleCodeChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 6) {
            setVerificationCode(value);
        }
    };

    return (
        <div className='sign-in'>
            <ToastContainer />
            <div className="forgot-mod">
                <div className="forgot-content">
                    <h1>Verify Your Email to Continue</h1>
                    <form onSubmit={handleVerification}>
                        <p>
                            Please enter the 6-digit code we sent to
                            <br />
                            <strong>{maskedEmail}</strong>
                        </p>
                        <input
                            type="text"
                            inputMode="numeric"
                            value={verificationCode}
                            onChange={handleCodeChange}
                            placeholder="Enter verification code"
                            required
                            maxLength="6"
                        />
                        <p className='resend-link'>
                            Didn't receive the email?{' '}
                            <span
                                onClick={!isResending ? handleResend : null}
                                style={{
                                    cursor: isResending ? 'not-allowed' : 'pointer',
                                    color: isResending ? 'gray' : '#007bff',
                                    textDecoration: isResending ? 'none' : 'underline'
                                }}
                            >
                                {isResending ? 'Resending...' : 'Resend Code'}
                            </span>
                        </p>

                        <button
                            type="submit"
                            className='primary-btn'
                            disabled={isProcessing}
                            style={{ cursor: isProcessing ? 'not-allowed' : 'pointer' }}
                        >
                            {isProcessing ? 'Processing...' : 'Verify and Continue'}
                        </button>

                        <p className='resend-link'>
                            Need to use a different email?{' '}
                            <Link to={"/sign-up"}>
                                <span
                                    style={{
                                        cursor: isResending ? 'not-allowed' : 'pointer',
                                        color: isResending ? 'gray' : '#007bff',
                                        textDecoration: isResending ? 'none' : 'underline'
                                    }}
                                >
                                    Update Email
                                </span>
                            </Link>
                        </p>

                        {isSent && <p className='warning'>Please check your inbox and spam folder for the email.</p>}
                    </form>
                </div>
            </div>
            <div className="sign-content"></div>
            <div className="fade-in"></div>
        </div>
    );
};

export default VerifyMail;
