import React, { useState, useContext } from 'react';
import './account.css';
import { FaLock, FaUser, FaEye, FaEyeSlash } from "react-icons/fa";
import { useLogin } from '../../context/UseLogin';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../context/AuthContext';
import { GoogleLogin } from '@react-oauth/google';  // Import GoogleLogin from the package

const SignIn = () => {
    const { login, error } = useLogin();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_BASE_API_URL;

    // Handle Google Sign-In response
    const handleGoogleSuccess = async (credentialResponse) => {
        if (credentialResponse?.credential) {
            try {
                const response = await fetch(`${API_URL}/api/user/auth/auth/google/callback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Include cookies in the request
                    body: JSON.stringify({ token: credentialResponse.credential }),
                });

                const data = await response.json();

                if (response.ok) {
                    toast.success('Signed in successfully!');

                     // Save user information in localStorage
                    localStorage.setItem('ojiiz_user', JSON.stringify(data));

                    // Save user data in context
                    dispatch({ type: 'LOGIN', payload: data });

                    if (data.isNewUser) {
                        toast.success('Welcome! Enjoy your free 5 oz.');

                        const timer = setTimeout(() => {
                            navigate('/');
                        }, 2000);
                        return () => clearTimeout(timer);

                    } else {
                        navigate('/'); // Redirect existing users to the home page
                    }
                } else {
                    toast.error(`Google sign-in failed: ${data.message || 'Unknown error'}`);
                }
            } catch (error) {
                console.error('Google sign-in error:', error);
                toast.error('An error occurred during Google sign-in');
            }
        } else {
            toast.error('Google Sign-In response was invalid.');
        }
    };


    const handleGoogleError = () => {
        toast.error('Google sign-in failed. Please try again.');
    };

    // Handle traditional sign-in submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast.error('Fill in all fields');
            return;
        }
        setIsSubmitting(true);
        await login(email, password, true);
        setIsSubmitting(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };


    return (
        <div className='sign-in'>
            <div className="sign-content">
                <h1>Sign in to your <br /><span> Dream</span></h1>
            </div>
            <ToastContainer />
            <div className="sign-fields">
                <h1>SIGN IN</h1>
                <p>Sign in with your email address</p>
                <form className='sign-form' onSubmit={handleSubmit}>
                    <div className="input-with-icon">
                        <FaUser className="icon" />
                        <input
                            type="text"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Username or email'
                            required
                            aria-label="Email"
                        />
                    </div>
                    <span>
                        <div className="input-with-icon password-field">
                            <FaLock className="icon" />
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Password'
                                required
                                aria-label="Password"
                            />
                            {showPassword ? (
                                <FaEyeSlash className="toggle-password" onClick={togglePasswordVisibility} aria-label="Hide password" />
                            ) : (
                                <FaEye className="toggle-password" onClick={togglePasswordVisibility} aria-label="Show password" />
                            )}
                        </div>
                        <Link to={"/forgot-password"}>
                            <div className="forgot-password">Forgot Password</div>
                        </Link>
                    </span>
                    <button type="submit" disabled={isSubmitting} className='primary-btn'>
                        {isSubmitting ?
                            <>
                                <div className="loader-btn"></div>
                                Processing...
                            </>
                            : 'Sign In'}
                    </button>

                    <div className='divider2'>
                        <div className='social-line'></div>
                        <span className='or'>or</span>
                        <div className='social-line'></div>
                    </div>

                    {/* Google Sign-In Button using the package */}
                    <div className="google-button">

                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={handleGoogleError}
                            shape="circle"
                            theme='outline'
                        />
                    </div>


                    <span>Don't have an account? <Link to={"/sign-up"}> Sign Up</Link></span>
                </form>
                {error && <p className="warning">{error}</p>}
            </div>
        </div>
    );
};

export default SignIn;