import React, { useState, useContext } from 'react';
import './account.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../context/AuthContext';
import ReactPixel from 'react-facebook-pixel';
import { GoogleLogin } from '@react-oauth/google';

const SignUp = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    companyName: '',
    termsAccepted: false
  });

  const [errors, setErrors] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setCnfShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const API_URL = process.env.REACT_APP_BASE_API_URL;

  const handleGoogleSuccess = async (credentialResponse) => {
    if (credentialResponse?.credential) {
      setIsSubmitting(true);
      try {
        const res = await fetch(`${API_URL}/api/user/auth/auth/google/callback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies in the request
          body: JSON.stringify({ token: credentialResponse.credential }),
        });

        const data = await res.json();

        if (res.ok) {
          toast.success('Signed in successfully!');
          // Save user information in localStorage
          localStorage.setItem('ojiiz_user', JSON.stringify(data));

          // Save user data in context
          dispatch({ type: 'LOGIN', payload: data });

          // Redirect user based on account type
          if (data.isNewUser) {
            toast.success('Welcome! Enjoy your free 5 oz.');

            const timer = setTimeout(() => {
              navigate('/');
            }, 2000);
            return () => clearTimeout(timer);

          } else {
            navigate('/'); // Redirect existing users to home
          }
        } else {
          toast.error(`Google sign-in failed: ${data.message || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Google sign-in error:', error);
        toast.error('An error occurred during Google sign-in');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error('Google Sign-In response was invalid.');
    }
  };


  const handleGoogleError = () => {
    toast.error('Google sign-in failed. Please try again.');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Reset errors
    setErrors({});

    // Check for errors before submitting
    const formErrors = {};
    Object.keys(formData).forEach((key) => {
      Object.assign(formErrors, validateField(key, formData[key], formData));
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      toast.error('Please correct the highlighted errors before submitting');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/user/auth/check-unique`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          userName: formData.userName,
          email: formData.email
        })
      });

      const data = await response.json();

      if (!data.unique) {
        toast.error(data.message);
        setIsSubmitting(false);
        return;
      }

      // Send verification email
      await fetch(`${API_URL}/api/user/auth/send-verification-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email: formData.email,
        })
      });

      // Save the verification code and user data in localStorage
      // localStorage.setItem('formData', JSON.stringify(formData));

      toast.success('Verification code sent to your email');
      // Track with Facebook Pixel
      ReactPixel.track('FormSuccess', { action: 'Verification Code Sent' });

      setTimeout(() => {
        navigate('/verify-mail', {
          state: {
            formData: formData,
            isGoogleUser: false,
            isVerified: false
          }
        })
      }, 2000);
    } catch (error) {
      toast.error('Failed to check uniqueness. Please try again later.');
      setIsSubmitting(false);
    }
  };

  // Helper function to format field names
  const getFieldLabel = (name) => {
    const labels = {
      firstName: 'First Name',
      lastName: 'Last Name',
      userName: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      termsAccepted: 'Term & Condition'
    };
    return labels[name] || name; // Default to the original name if no label is found
  };

  const validateField = (name, value, currentFormData) => {
    const fieldErrors = {};

    // Check if required fields are filled
    if (['firstName', 'lastName', 'userName', 'email', 'password', 'confirmPassword'].includes(name)) {
      if (!value.trim()) {
        fieldErrors[name] = `${getFieldLabel(name)} is required.`;
      }
    }

    // Validate email syntax
    if (name === 'email' && value) {
      if (!isValidEmail(value)) {
        fieldErrors[name] = 'Please enter a valid email address.';
      }
    }

    if (name === 'userName') {
      if (value.length < 5 || value.length > 15) {
        fieldErrors[name] = 'Username must be 5–15 characters long.';
      }
    }

    // Validate password strength
    if (name === 'password' && value) {
      if (!isStrongPassword(value)) {
        fieldErrors[name] = 'Password must be 8+ chars, with uppercase, lowercase, and a number.';
      }
    }

    // Check password match
    if (name === 'confirmPassword' && value) {
      if (value !== currentFormData.password) {
        fieldErrors[name] = 'Passwords do not match.';
      }
    }

    // Validate phone number contains only digits
    if (name === 'phoneNumber' && value) {
      const phoneNumberRegex = /^[0-9]+$/;
      if (!phoneNumberRegex.test(value)) {
        fieldErrors[name] = 'Phone Number must contain only numbers.';
      }
    }
    if (name === 'termsAccepted') {
      if (!value) {
        fieldErrors[name] = 'Please Check the Term & Conditions.';
      }
    }
    return fieldErrors;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...validateField(name, value, formData),
    }));
  };

  const handleSuggestionClick = async (suggestion) => {
    setFormData({ ...formData, userName: suggestion });
    const suggestions = await checkUsername(suggestion);
    setSuggestions(suggestions);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      let updatedFormData = { ...prevState, [name]: value };

      // Real-time validation on change
      const fieldErrors = validateField(name, value, updatedFormData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...fieldErrors,
      }));

      // Clear specific error if the field is valid
      if (Object.keys(fieldErrors).length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined, // Clear the specific error for this field
        }));
      }

      // Username generation and checking for suggestions
      if (name === 'firstName' || name === 'lastName') {
        let userName = generateUserName(updatedFormData.firstName, updatedFormData.lastName);
        userName = userName.replace(/[^a-zA-Z0-9_]/g, '');
        updatedFormData.userName = userName;

        checkUsername(userName).then((suggestions) => setSuggestions(suggestions));
      }

      if (name === 'userName') {
        updatedFormData.userName = value.replace(/[^a-zA-Z0-9_]/g, '');
      }

      return updatedFormData;
    });
  };

  const generateUserName = (firstName, lastName) => {
    return `${firstName}_${lastName}`.toLowerCase();
  };

  const checkUsername = async (username) => {
    try {
      const response = await fetch(`${API_URL}/api/user/auth/check-username`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ username }),
      });
      const data = await response.json();
      return data.suggestions || [];
    } catch (error) {
      return [];
    }
  };

  // Utility function to check email syntax
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isStrongPassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setCnfShowPassword(!showCnfPassword);
  };

  return (
    <div className='sign-in'>
      <ToastContainer />
      <div className="sign-content">
        <h1>Sign Up to your<br /><span>Success</span></h1>
      </div>
      <div className="sign-up-heading">
        <h2>SIGN UP</h2>
        {/* line */}
        <div className="line-container">
          <div className="dot-container">
            <div className="dot start"></div>
            <div className="dot-text">Sign Up</div>
          </div>
          <div className="line"></div>
          <div className="dot-container">
            <div className="dot mid"></div>
            <div className="dot-text"></div>
          </div>
          <div className="line"></div>
          <div className="dot-container">
            <div className="dot end"></div>
            <div className="dot-text"></div>
          </div>
        </div>
      </div>

      <div className="sign-up-fields">
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="input-field">
              <input
                className={errors.firstName ? 'error' : ''}
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder='First Name'
                required
              />
              {errors.firstName && <span className="error-msg">{errors.firstName}</span>}
            </div>
            <div className="input-field">
              <input
                className={errors.lastName ? 'error' : ''}
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder='Last Name'
                required
              />
              {errors.lastName && <span className="error-msg">{errors.lastName}</span>}
            </div>
          </div>
          <div className="input-field">
            <input
              className={errors.userName ? 'error' : ''}
              type="text"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='User Name'
              required
            />
            {errors.userName && <span className="error-msg">{errors.userName}</span>}

            {suggestions.length > 0 && (
              <div className="suggestions">
                <h3>Username taken. Try one of these:</h3>
                {suggestions.map((suggestion) => (
                  <p key={suggestion} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </p>
                ))}
              </div>
            )}
          </div>

          <div className="input-field">
            <input
              className={errors.email ? 'error' : ''}
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Email'
              required
            />
            {errors.email && <span className="error-msg">{errors.email}</span>}
          </div>

          <div className="password-field">
            <input
              className={errors.password ? 'error' : ''}
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Password'
              required
            />

            {showPassword ? <FaEyeSlash className="toggle-password" onClick={togglePasswordVisibility} /> : <FaEye className="toggle-password" onClick={togglePasswordVisibility} />}

            {errors.password && <span className="error-msg">{errors.password}</span>}
          </div>

          <div className="password-field">
            <input
              className={errors.confirmPassword ? 'error' : ''}
              type={showCnfPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Confirm Password'
              required
            />

            {showCnfPassword ? <FaEyeSlash className="toggle-password" onClick={toggleConfirmPasswordVisibility} /> : <FaEye className="toggle-password" onClick={toggleConfirmPasswordVisibility} />}

            {errors.confirmPassword && <span className="error-msg">{errors.confirmPassword}</span>}
          </div>

          <div className="input-field">
            <input
              className={errors.phoneNumber ? 'error' : ''}
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Phone Number (Optional)'
              required
            />
            {errors.phoneNumber && <span className="error-msg">{errors.phoneNumber}</span>}
          </div>

          <div className="input-field">
            <input
              className={errors.companyName ? 'error' : ''}
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Company Name (Optional)'
            />
            {errors.companyName && <span className="error-msg">{errors.companyName}</span>}
          </div>

          <label htmlFor="terms" className="terms-label">
            <input
              type="checkbox"
              name="termsAccepted"
              id='terms'
              checked={formData.termsAccepted}
              onChange={(e) => setFormData({ ...formData, termsAccepted: e.target.checked })}
              required
            />
            By registering with us, you agree to our
            <a href="https://ojiiz.com/terms-and-conditions/" target='_blank' rel='noreferrer' >
              <span>Terms and Conditions</span>
            </a>
          </label>
          {errors.termsAccepted && <span className="error-msg">{errors.termsAccepted}</span>}


          <button type="submit" disabled={isSubmitting} className='primary-btn'>
            {isSubmitting ?
              <>
                <div className="loader-btn"></div>Processing
              </> :
              'Sign Up'
            }
          </button>

          <div className='divider2'>
            <div className='social-line'></div>
            <span className='or'>or</span>
            <div className='social-line'></div>
          </div>

          <div className="google-button">
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
              shape="circle"
              theme='outline'
              text='signup_with'
            />
          </div>

          <span>Already have an account? <Link to={"/sign-in"}>Sign In</Link></span>
        </form>
      </div>
      <div className="fade-in"></div>
    </div>
  );
};

export default SignUp;
