import React, { useState, useEffect } from 'react';
import { Footer, NavBar, SideBar, BillingForm } from '../components';
import { useAuthContext } from '../hooks/useAuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PLANS = [
    { oz: 60, name: 'Starter', rate: 0.25 }
];

const ChoosePlan = () => {
    const { ojiiz_user } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(60); // Initial value set to 50 oz
    const [amountPerOz, setAmountPerOz] = useState(0.25);
    const [isPlanValid, setIsPlanValid] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updatedUserData, setUpdatedUserData] = useState(null);

    const API_URL = process.env.REACT_APP_BASE_API_URL;

    // Adjustable oz limit values
    const MIN_OZ = 60;
    const MAX_OZ = 1800; // Adjusted max oz based on the ultimate plan

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_URL}/api/user/ojiiz/user-profile/${ojiiz_user.userName}`, {
                    credentials: 'include',
                });

                if (!response.ok) throw new Error(`Error: ${response.statusText}`);

                const data = await response.json();
                setUserData(data.user);
                setInitialPlan(data.user.totalCredit);
            } catch (error) {
                toast.error(error.message || 'An error occurred while fetching user data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [ojiiz_user.userName, API_URL]);

    const setInitialPlan = (totalCredit) => {
        // Automatically select plan based on totalCredit
        const selectedPlan = PLANS.find(plan => totalCredit >= plan.oz);
        const planValue = selectedPlan ? selectedPlan.oz : MIN_OZ;
        setSelectedPlan(planValue);
        updateAmountPerOz(planValue);
        validatePlan(planValue);
    };

    const handlePlanChange = (event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            setSelectedPlan(value);
            updateAmountPerOz(value);
            validatePlan(value);
        }
    };

    const handleRangeValueClick = (value) => {
        setSelectedPlan(value);
        updateAmountPerOz(value);
        validatePlan(value);
    };

    const updateAmountPerOz = (value) => {
        if (value < 100) {
            setAmountPerOz(0.25); // Starter pricing
        }
    };

    const validatePlan = (value) => {
        setIsPlanValid(value >= MIN_OZ); // Plan is valid if it's >= MIN_OZ
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (selectedPlan < MIN_OZ) {
            toast.info(`You can buy a minimum of ${MIN_OZ} oz credits.`);
            return;
        }
        if (selectedPlan > 10000) {
            toast.info(`You can't buy more than 10000 oz credits.`);
            return;
        }

        let planType = 'advanced'; // Maintain existing planType if it exists


        try {
            setLoading(true);
            const { savedJobs, ...restUserData } = userData;
            const totalPrice = selectedPlan * amountPerOz;

            const newUserData = { ...restUserData, selectedPlan, planType, totalPrice };
            setUpdatedUserData(newUserData);

            // Show confirmation modal instead of window.confirm
            setIsModalOpen(true);
        } catch (error) {
            toast.error('Failed to checkout. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const calculatePosition = (value) => {
        const range = MAX_OZ - MIN_OZ;
        return ((value - MIN_OZ) / range) * 100;
    };

    return (
        <div>
            <div className="jobDetail-navbar">
                <NavBar />
                <h1>Choose Plan</h1>
            </div>
            <ToastContainer />

            {loading && <div className="loader"></div>}
            <div className="your-plan">
                <SideBar />
                <div className="plan-content">

                    <div className="plan-range">
                        <h2>Buy oz</h2>
                        <p>Choose the number of oz you want to buy. You can select a minimum of {MIN_OZ} oz.</p>
                        <div className="range-wrapper">
                            <input
                                type="range"
                                min={MIN_OZ}
                                max={MAX_OZ}
                                value={selectedPlan}
                                onChange={handlePlanChange}
                                className="range-slider"
                                step="1"
                                aria-label="Select oz amount"
                            />
                            <div className="range-values">
                                {Array.from({ length: 9 }, (_, index) => MIN_OZ + 200 * index)
                                    .concat(MAX_OZ)
                                    .map((value, index) => (
                                        <span
                                            key={index}
                                            className={`range-value ${value === selectedPlan ? 'active' : ''}`}
                                            onClick={() => handleRangeValueClick(value)}
                                            style={{ left: `${calculatePosition(value)}%` }}
                                        >
                                            {value}
                                        </span>
                                    ))}
                            </div>
                        </div>
                        <div className="selected-plan">Selected Plan: {selectedPlan} oz</div>
                    </div>

                    {/* <div className="plan-range">
                        <h2>Plan</h2>
                        <div className="rate-list">
                            {PLANS.map((plan) => (
                                <label
                                    key={plan.oz}
                                    className={`plan-option ${selectedPlan === plan.oz ? 'selected' : ''} ${userData?.planType === plan.name.toLowerCase() ? 'current-plan' : ''}`}
                                >
                                    <input
                                        type="radio"
                                        name="plan"
                                        value={plan.oz}
                                        checked={selectedPlan === plan.oz}
                                        onChange={() => handleRangeValueClick(plan.oz)}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="oz-plan-detail">
                                        <span>
                                            <h3>{plan.name}</h3>
                                            <p>{`${plan.oz === 50 ? 'Free' : plan.oz === 100 ? '$49' : '$99'} `}</p>
                                        </span>
                                        <i>{`${plan.oz === 50 ? '0-99oz' : plan.oz === 100 ? '100-219oz' : 'above 220oz'} : $${plan.rate}/oz`}</i>
                                        <button onClick={() => handleRangeValueClick(plan.oz)}>
                                            {userData?.planType === plan.name.toLowerCase() ? 'Current Plan' : 'Upgrade'}
                                        </button>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div> */}

                    <div className="your-plan-detail">
                        <b>Your available oz</b>
                        <span>{userData ? userData.totalCredit - userData.usedCredit : 'Loading...'}</span>

                        <b>Enter Your Amount of oz</b>
                        <span>
                            <input
                                type="number"
                                min={MIN_OZ}
                                value={selectedPlan}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value);
                                    setSelectedPlan(value);
                                    updateAmountPerOz(value);
                                    validatePlan(value);
                                }}
                            />
                            <i>You can buy a minimum of {MIN_OZ} oz</i>
                        </span>

                        {!isPlanValid && <p className="error-message">You can buy a minimum of {MIN_OZ} oz credits.</p>}
                        <span>
                            <b>Your account will be charged</b><i>(${amountPerOz}/oz)</i>
                        </span>
                        <span>
                            {/* Update the displayed amount to charge */}
                            {(selectedPlan * amountPerOz).toFixed(2)}$
                            <i></i>
                        </span>

                        <b>Your new oz balance</b>
                        <span>
                            {userData ? userData.totalCredit - userData.usedCredit + selectedPlan : 'Loading...'}
                        </span>

                        <button
                            disabled={!isPlanValid || loading}
                            onClick={handleSubmit}
                        >
                            {loading ? 'Processing...' : 'Buy'}
                        </button>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <BillingForm onClose={closeModal} updatedUserData={updatedUserData} redirectUrl="/choose-plan" />
            )}

            <Footer />
        </div>
    );
};

export default ChoosePlan;
