import React from 'react';
import Modal from 'react-modal';

const VideoPopup = ({ isOpen, onRequestClose }) => {
    const videoId = "CZwgKRTLB-8"; // Extract the video ID for flexibility
    const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&enablejsapi=1&rel=0`;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent overlay
                    zIndex: 1000, // Ensure the modal is on top
                },
                content: {
                    backgroundColor: 'transparent', // Transparent modal content background
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0', // Remove default padding
                },
            }}
            contentLabel="Video Popup"
        >
            <div className="video-modal">
                <div className="video-header">
                    <h2>Here’s how to win a project on Ojiiz in a few steps.</h2>
                    <span className="close-button" onClick={onRequestClose}>&times;</span>
                </div>
                {/* Embedded YouTube video */}
                <div className="video-body">

                    <iframe
                        src={embedUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '8px',
                        }}
                    ></iframe>
                </div>


            </div>
        </Modal>
    );
};

export default VideoPopup;
