import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuthContext } from '../hooks/useAuthContext';  // Assumed to have logout functionality
import { useLogout } from './AdminLogout';

const UserCreditContext = createContext();

export const useUserCredit = () => {
    return useContext(UserCreditContext);
};

export const UserCreditProvider = ({ children }) => {
    const [Credit, setCredit] = useState(0); // Remaining credit state
    const { ojiiz_user } = useAuthContext();  // Destructure the logout function from context
    const { logout } = useLogout();

    // Function to fetch user data
    const fetchUserData = useCallback(async () => {
        if (!ojiiz_user?.userName) {
            return; // Exit early if userName is not defined
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/user/ojiiz/user-credit/${ojiiz_user.userName}`, {
                credentials: 'include', // Include cookies in the request
            });

            if (response.ok) {
                const data = await response.json();
                const rem = data.user.totalCredit - data.user.usedCredit;
                setCredit(rem);
            } else if (response.status === 404 || response.status === 401) {
                toast.error('User not found. Logging you out...');
                setTimeout(() => {
                    // If user not found, log them out
                    logout();  // Call the logout function
                }, 2000);
            } else {
                toast.error('An error occurred while fetching user data. Please try again later.');
            }
        } catch (error) {
            toast.error('An error occurred while fetching user data. Please try again later.');
        }
    }, [ojiiz_user, logout]);

    // Use effect to fetch data when component mounts or when ojiiz_user changes
    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    // Function to update credit locally
    const updateCredits = (newCredits) => {
        setCredit(newCredits);
    };

    return (
        <UserCreditContext.Provider value={{ Credit, updateCredits }}>
            {children}
        </UserCreditContext.Provider>
    );
};
