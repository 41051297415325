import React, { useContext, useEffect, useState } from 'react';
import { Check, X } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { AuthContext } from '../../context/AuthContext';

const Plan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const API_URL = process.env.REACT_APP_BASE_API_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const { dispatch } = useContext(AuthContext);


    const formData = location.state?.formData || {};

    const isGoogleUser = location.state?.isGoogleUser || false;
    const isVerified = location.state?.isVerified || false;


    useEffect(() => {
        if (!isVerified && !isGoogleUser) {
            toast.promise(
                new Promise(resolve => setTimeout(resolve, 3000)),
                {
                    pending: 'Please select plan to proceed.',
                    success: '',
                    error: ''
                }
            ).then(() => navigate('/sign-up'));
        }
    }, [isVerified, isGoogleUser, navigate]);


    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            if (!isGoogleUser) {
                const requiredFields = ['firstName', 'lastName', 'userName', 'email', 'password', 'confirmPassword'];
                const emptyFields = requiredFields.filter(field => !formData[field]);

                if (emptyFields.length > 0) {
                    toast.error('Some required fields are empty. Please fill them in.');
                    return;
                }

                if (!validator.isEmail(formData.email)) {
                    toast.error('Invalid email format');
                    return;
                }

                if (formData.password !== formData.confirmPassword) {
                    toast.error('Passwords do not match.');
                    return;
                }

                // Only create user if not a Google user
                const createUserResponse = await fetch(`${API_URL}/api/user/ojiiz/user-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(formData),
                });

                if (!createUserResponse.ok) {
                    const createUserData = await createUserResponse.json();
                    toast.error(`Failed to sign up: ${createUserData.error}`);
                    return;
                }

                const createUserData = await createUserResponse.json(); // Await the json response
                localStorage.setItem('ojiiz_user', JSON.stringify(createUserData));
                dispatch({ type: 'LOGIN', payload: createUserData });
                toast.success('Enjoy your 5 free oz');
                setTimeout(() => navigate('/'), 2000);
            } else {
                // If isGoogleUser is true, redirect to the home route
                toast.success('Enjoy your 5 free oz');
                setTimeout(() => navigate('/'), 2000);
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <ToastContainer />
            <div className='choose-plans new-plan-page'>
                <div className="sign-up-heading">
                    <h2>SIGN UP</h2>
                    <div className="line-container">
                        <div className="dot-container">
                            <div className="dot start"></div>
                            <div className="dot-text"></div>
                        </div>
                        <div className="line"></div>
                        <div className="dot-container">
                            <div className="dot mid"></div>
                            <div className="dot-text">
                                Plan
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="dot-container">
                            <div className="dot end"></div>
                            <div className="dot-text"></div>
                        </div>
                    </div>
                </div>

                <div className="new-plan-section">

                    <div className="new-plan-feature">
                        <ul>
                            <li>
                                5 Free Oz Credits
                                <span>
                                    <Check />
                                </span>
                            </li>
                            <li>
                                No Credit card required
                                <span>
                                    <Check />
                                </span>
                            </li>
                            <li>
                                Pay as you go
                                <span>
                                    <Check />
                                </span>
                            </li>
                            <li>
                                24/7 urgent support
                                <span>
                                    <Check />
                                </span>
                            </li>
                            <li>
                                Get unlimited access
                                <span>
                                    <Check />
                                </span>
                            </li>
                            <li>
                                Get 1 insights blog
                                <span>
                                    <Check />
                                </span>
                            </li>
                            <li>
                                Access to accounts manager
                                <span>
                                    <X color='#3E3E3E' />
                                </span>
                            </li>

                        </ul>
                    </div>
                    <div className="new-plan">
                        <h1>Starter</h1>
                        <div className="new-plan-info">
                            *No Credit Card Required
                        </div>
                        <div className="new-plan-text">
                            <div className="new-plan-line"></div>
                            <h3>$0</h3>
                            <p>Direct Access to Decision-Makers
                                <br />
                                is Just a Click Away.
                            </p>
                            <div className="new-plan-line"></div>
                        </div>
                        <button
                            className='new-plan-btn'
                            disabled={isLoading}
                            style={{
                                cursor: isLoading ? 'not-allowed' : 'pointer',
                                opacity: isLoading ? 0.6 : 1
                            }}
                            onClick={handleSubmit}
                        >
                            {isLoading ? 'Signing Up...' : 'Sign Up For Free'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Plan
