import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AnimatedCounter, Footer, Hero, Jobs, SideBar, VideoPopup } from '../components';
import './home.css';
import { FaCoins } from "react-icons/fa6";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { useAuthContext } from '../hooks/useAuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserCredit } from '../context/UserCreditContext';

ChartJS.register(ArcElement, Tooltip);

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState();
    const [todayLength, setTodayLength] = useState();
    const { ojiiz_user } = useAuthContext();
    const [latestJobs, setLatestJobs] = useState([]);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_BASE_API_URL;
    const { Credit } = useUserCredit();
    const [isOpen, setIsOpen] = useState(false); // State to control video popup visibility


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 3000); // 3000ms = 3 seconds

        return () => clearTimeout(timer);
    }, []);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [latestJobsResponse, userProfileResponse] = await Promise.all([
                fetch(`${API_URL}/api/user/ojiiz/feature-job`, { credentials: 'include', }),
                fetch(`${API_URL}/api/user/ojiiz/user-credit/${ojiiz_user.userName}`, { credentials: 'include', })
            ]);

            if (!latestJobsResponse.ok || !userProfileResponse.ok) {
                throw new Error('Error fetching data');
            }

            const latestJobsData = await latestJobsResponse.json();
            const userData = await userProfileResponse.json();

            setLatestJobs(latestJobsData.latestJobs);
            setTodayLength(latestJobsData.totalTodayJobs);
            setUserData(userData.user);
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    }, [API_URL, ojiiz_user.userName]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const totalCredit = userData?.totalCredit ?? 0;
    const usedCredit = userData?.usedCredit ?? 0;
    const progress = totalCredit ? ((totalCredit - usedCredit) / totalCredit) * 100 : 0;

    // Memoize the chart data to avoid unnecessary re-renders
    const doughnutData = useMemo(() => ({
        labels: ['Remaining Credits', 'Used Credits'],
        datasets: [{
            data: [totalCredit - usedCredit, usedCredit],
            backgroundColor: ['#7672EC', '#FFC2FD'],
            hoverBackgroundColor: ['#7672EC', '#FFC2FD'],
            borderWidth: 1,
        }],
    }), [totalCredit, usedCredit]);

    const handleJobClick = useCallback(() => {
        toast.info("You have no more oz.");
        setTimeout(() => navigate("/choose-plan"), 2000);
    }, [navigate]);

    const SavedJobs = useMemo(() => (
        <div className="stats">
            <p>Saved Jobs</p>
            <br />
            <span>{userData?.savedJobs?.length ?? 0}</span>
        </div>
    ), [userData?.savedJobs]);

    const PlanOverview = useMemo(() => (
        <div className="stats overview-stats">
            <div className="stats-data">
                <p>Plan Overview</p>
                <div>{usedCredit}/{totalCredit}<span>oz</span></div>
            </div>
            <CircularProgressbar
                value={progress}
                text={`${Math.round(progress)}%`}
                className='circle-bar'
                styles={{
                    path: { stroke: '#213157' },
                    text: { fill: '#213157' },
                }}
            />
        </div>
    ), [progress, totalCredit, usedCredit]);

    const TodayJobs = useMemo(() => (
        <div className="stats">
            <p>Today jobs</p>
            <br />
            <span>
                <AnimatedCounter value={`${todayLength + 2000}`} />
            </span>
        </div>
    ), [todayLength]);

    const FeatureJobs = useMemo(() => (
        <div className="feature-job" id='jobListSection' style={{ width: '50%' }}>
            <h1>Latest Jobs</h1>
            {latestJobs.slice(0, 4).map(job => (
                <React.Fragment key={job._id}>
                    {Credit <= 0 ? (
                        <div onClick={handleJobClick}>
                            <Jobs job={job} />
                        </div>
                    ) : (
                        <Link to={`/jobs-detail/${job._id}`}>
                            <Jobs job={job} />
                        </Link>
                    )}
                </React.Fragment>
            ))}
            <div className="see-more-btn">
                <Link to={"/jobs"}>
                    <button>See All</button>
                </Link>
            </div>
        </div>
    ), [latestJobs, Credit, handleJobClick]);

    // Memoized CreditInfo to prevent multiple renders
    const CreditInfo = useMemo(() => (
        <div className="creds-stats">
            <div className="creds-header">
                Credit Info
            </div>
            <div className="creds-body">
                <div className="doughnut-chart">
                    <Doughnut
                        data={doughnutData}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,  // Hide the legend
                                },
                            },
                        }}
                    />
                </div>

                <div className="creds-details">
                    <div className="creds-color">
                        <div className="color-name">
                            <span></span>
                            <h4>Remaining Credits</h4>
                        </div>
                        <div className='cred-value'>
                            <FaCoins color='#7672EC' />
                            {`${totalCredit - usedCredit}/${totalCredit}`}
                        </div>
                    </div>
                    <div className="creds-color">
                        <div className="color-name">
                            <span style={{ backgroundColor: '#FFC2FD' }}></span>
                            <h4>Used Credits</h4>
                        </div>
                        <div className='cred-value'>
                            <FaCoins color='#7672EC' />
                            {`${usedCredit}/${totalCredit}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ), [doughnutData, totalCredit, usedCredit]);

    return (
        <div className='page'>
            <Hero heading="Find Your Relevant Jobs Today" home={true} />
            <ToastContainer />
            <div className="page-content">
                <SideBar />
                {isLoading ? (
                    <div className="loader"></div>
                ) : (
                    <div className="page-detail">

                        <div className="page-stats" id='statsSection'>
                            <Link to="/jobs">
                                {TodayJobs}
                            </Link>
                            <Link to="/overview">
                                {PlanOverview}
                            </Link>
                            <Link to="/saved-jobs">
                                {SavedJobs}
                            </Link>
                        </div>

                        <div className="feature-jobs">
                            {FeatureJobs}
                            <div className="video-side">
                                <div className="creds-stats video-stats">
                                    <div className="creds-header">
                                        You haven’t won a project yet.
                                    </div>
                                    <div className="creds-body">
                                        <div className="row">
                                            {/* Embedded YouTube video with autoplay */}
                                            <iframe
                                                src="https://www.youtube.com/embed/CZwgKRTLB-8?autoplay=1&mute=1&loop=1&playlist=CZwgKRTLB-8&rel=0"
                                                title="How to win a project on Ojiiz"
                                                width="230"
                                                height="150"
                                                className="poster-img"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                style={{ cursor: 'pointer', borderRadius: '8px' }}
                                                onClick={() => setIsOpen(true)}
                                            ></iframe>
                                            <div className="video-content">
                                                <h3>Follow these steps:</h3>
                                                <ul>
                                                    <li>Search for your relevant niche.</li>
                                                    <li>Unlock the details.</li>
                                                    <li>Pitch to them directly.</li>
                                                    <li><b>Hurry!</b> The project is now yours.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {CreditInfo}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isOpen && (
                <VideoPopup isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
            )}
            <Footer />
        </div>
    );
};

export default Home;
