import React from 'react';
import { FaGlobe, FaUserTie, FaAlignCenter, FaLock, FaFileExport } from "react-icons/fa6";
import rocket from '../assets/rocket.png';
import { Link, useLocation } from 'react-router-dom';
import { IoBookmark } from "react-icons/io5";
import 'react-toastify/dist/ReactToastify.css';
import { useUserCredit } from '../context/UserCreditContext';

const SideBar = () => {
    const { Credit } = useUserCredit();

    const location = useLocation();

    return (
        <div>
            <div className="side-bar">
                <ul>
                    <Link to="/overview">
                        <li className={location.pathname === '/overview' ? 'active' : ''}><FaGlobe size={24} />Overview</li>
                    </Link>
                    <Link to="/profile">
                        <li className={location.pathname === '/profile' ? 'active' : ''}><FaUserTie size={24} />Profile</li>
                    </Link>
                    <Link to="/choose-plan">
                        <li className={location.pathname === '/choose-plan' ? 'active' : ''}><FaAlignCenter size={24} />Buy oz</li>
                    </Link>
                    <Link to="/password-change">
                        <li className={location.pathname === '/password-change' ? 'active' : ''}><FaLock size={24} />Security</li>
                    </Link>

                    <Link to="/export-csv">
                        <li className={location.pathname === '/export-csv' ? 'active' : ''}><FaFileExport size={24} />Export to CSV</li>
                    </Link>

                    <Link to="/saved-jobs">
                        <li className={location.pathname === '/saved-jobs' ? 'active' : ''}><IoBookmark size={24} />Saved Jobs</li>
                    </Link>

                </ul>
                <div className="update">
                    <img src={rocket} alt="" />
                    <div className="update-content">
                        <p>Credit left: {Credit ? Credit : '0'} oz</p>
                        <Link to={"/choose-plan"}>
                            <button>Upgrade Now</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="bottom-side-bar">
                <ul>
                    <Link to="/overview">
                        <li className={location.pathname === '/overview' ? 'active' : ''}><FaGlobe size={24} /></li>
                    </Link>
                    <Link to="/profile">
                        <li className={location.pathname === '/profile' ? 'active' : ''}><FaUserTie size={24} /></li>
                    </Link>
                    <Link to="/choose-plan">
                        <li className={location.pathname === '/choose-plan' ? 'active' : ''}><FaAlignCenter size={24} /></li>
                    </Link>
                    <Link to="/password-change">
                        <li className={location.pathname === '/password-change' ? 'active' : ''}><FaLock size={24} /></li>
                    </Link>

                    <Link to="/export-csv">
                        <li className={location.pathname === '/export-csv' ? 'active' : ''}><FaFileExport size={24} /></li>
                    </Link>


                    <Link to="/saved-jobs">
                        <li className={location.pathname === '/saved-jobs' ? 'active' : ''}><IoBookmark size={24} /></li>
                    </Link>

                </ul>
            </div>
        </div>

    );
}

export default SideBar;
